import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import * as Styles from "./dedicatedInfoBlock/styles.module.scss";

function DedicatedInfoBlock({ tags }) {
  const defaultImage = useResponsiveWithHydrationFix(
    <StaticImage src="../../images/pdp/info-block-mobile.jpg" alt="Dedicated Info Block" />,
    <StaticImage src="../../images/pdp/info-block-desktop.jpg" alt="Dedicated Info Block" />
  );

  const solidGoldImage = useResponsiveWithHydrationFix(
    <StaticImage
      src="../../images/pdp/info-block-solid-gold-mobile.jpg"
      alt="Dedicated Info Block"
    />,
    <StaticImage
      src="../../images/pdp/info-block-solid-gold-desktop.jpg"
      alt="Dedicated Info Block"
    />
  );

  return (
    <div className={Styles.dedicatedInfoBlock}>
      {tags.includes("Components:Solid Gold") ? solidGoldImage : defaultImage}
    </div>
  );
}

export default DedicatedInfoBlock;

DedicatedInfoBlock.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};
